import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';


function getRoomsFromApi() {
  return fetch('http://192.168.50.201:31000/active-rooms')
  .then((response) => response.json())
  .then((responseJson) => {
    return responseJson;
  })
  .catch((error) => {
    console.error(error);
  });
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https:/www.freeptt.net">
        Network Radio Systems
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));
const tiers = [
  {
    title: 'Public Groups',
    subheader: 'Anyone can join and use',
    price: '0',
    description: ['1000 Open Channels', ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Reserved Groups',
    subheader: 'Groups for specific interests',
    price: '5',
    description: [
                  'Access policy controlled'],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Private Calls',
    subheader: 'Secure one-to-one calls.',
    price: '15',
    description: ['End to end encrypted'],

    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Broadcast Groups',
    subheader: 'Free to listen radio chatter.',
    price: '30',
    description: [
      'Anyone can Listen',
      'Public safety scanner feeds',
    ],
    buttonText: '',
    buttonVariant: 'outlined',
  },
  {
    title: 'HAM Radio Patch',
    subheader: 'Access to Amateur Radio.',
    price: '30',
    description: [
      'Anyone can Listen',
      'License required to talk',
    ],
    buttonText: '',
    buttonVariant: 'outlined',
  },
  {
    title: 'GMRS Radio Patch',
    subheader: 'GMRS Repeater extended.',
    price: '30',
    description: [
      'Anyone can Listen',
      'License required to talk',
    ],
    buttonText: '',
    buttonVariant: 'outlined',
  },
];

export default function Talkgroups() {


  const classes = useStyles();


  return (
    <React.Fragment>
   

      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Talkgroups
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
          FREEPTT Network Radio is based around the concept of talkgroups where a unique talkgroup ID is assigned to each device and channel on the system.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>

                  <ul>
                    {tier.description.map((line) => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>


                </CardActions>
              </Card>
            </Grid>
          ))}

        </Grid>
        


      </Container>


    </React.Fragment>
  );
}